@import 'src/variables';
@import 'src/mixins';

.wrapper {
  border-radius: 10px;
  background-color: $white;
  max-width: 16.875rem;
  width: 100%;
  position: relative;
  color: initial;
  &.insightsWrapper {
    cursor: pointer;
    @include transition(0.3s linear);
    &:hover {
      box-shadow: 0 0 17px 6px rgba(0, 0, 0, 0.1);
    }
  }

  @include transition(0.3s linear);

  @media (min-width: $tablet) {
    &:hover {
      cursor: pointer;
      transform: scale(1.07);
      z-index: 1;
    }
  }
}

@media (max-width: $bigMobile) {
  .active {
    transform: scale(1.07);
    z-index: 1;
  }
}

.tourImage {
  opacity: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  max-height: 165px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.insightsWrapper {
  &:hover {
    .inner {
      opacity: 0.1;
    }
    .innerBottom {
      opacity: 0.1;
    }
    .tourImage {
      opacity: 0.5;
    }
  }
}

.inner {
  opacity: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  padding: 1rem 1rem 1.5rem 1rem;
  p {
    color: initial;
  }
}
.innerBottom {
  opacity: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  padding: 0 1rem 1rem 1rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
  }
  > img {
    margin: 0 5px 0 0;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0 0.563rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text {
  font-size: 0.875rem;
  margin: 0;
  &.date {
    margin: 0.75rem 0 0 0;
    opacity: 0.5;
  }
  &.address {
    min-height: 2.4rem;
    max-height: 2.4rem;
    line-height: 1.2rem;
    text-overflow: ellipsis;
    overflow: hidden;

    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.bigText {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  display: flex;
  img {
    margin: 0 0 0 5px;
  }
}
.smallText {
  font-size: 0.75rem;
  margin: 0;
}

.shareButton {
  padding: 0.625rem;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #fff;
  border-radius: 8px;
  z-index: 1;
}
.wrapper {
  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    z-index: 999;
    background: rgba(0, 0, 0, 0);

    -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
  }
  &:hover {
    .overlay {
      background: rgba(0, 0, 0, 0.25);
    }
    .viewDataButton {
      opacity: 1;
    }
  }
  .viewDataButton {
    opacity: 0;
    transition: opacity 0.2s linear;
    background-color: rgba(255, 255, 255, 0.35);
    border: none;
    height: 120px;
    width: 120px;
    padding: 2rem;
    text-align: center;
    font-size: 1.15rem;
    border-radius: 75px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px 2px;
    backdrop-filter: blur(8px);
  }
  .viewDataButtonText {
    margin-top: -1.2rem;
    line-height: 1.1;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    span {
      font-size: 2rem;
    }
  }
  .viewDataIcon {
    line-height: 1.35;
  }
}

.pendingImageOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 165px;
  padding: 0 30px;
  border: 1px solid;

  span {
    margin-bottom: 1rem;
    font-size: 26px;
    color: $white;
    text-align: center;
    line-height: 2rem;
  }
}
