@import 'src/variables';

.checkbox {
  font-size: 1rem;

  @media (max-width: $tablet) {
    font-size: 0.875rem;
    font-weight: 500;
  }

  :global(.ant-checkbox) {
    top: 0;
  }

  &:global(.ant-checkbox-wrapper) {
    align-items: center;
    margin: 0 !important;
  }

  span[class='ant-checkbox-inner'] {
    background: white;
    border: 2px solid $pink !important;
    border-radius: 4px;
  }

  span[class*='ant-checkbox-checked'] {
    &:after {
      border-color: $pink;
    }

    span[class='ant-checkbox-inner'] {
      background: $pink;
      border: 2px solid $pink !important;

      &::after {
        width: 0.5rem;
      }
    }
  }

  span[class='ant-checkbox-inner'] {
    width: 1.375rem;
    height: 1.375rem;

    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 0.75rem;
      height: 0.75rem;

      background-color: $pink;
    }
  }
}
