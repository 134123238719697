@import 'src/variables';

.modal {
  max-width: 600px;
  width: 95% !important;
  border-radius: 16px;

  :global(.ant-modal-content) {
    border-radius: 16px;
  }
}

.container {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 30px;
  }

  img.downgrade {
    transform: rotate(180deg);
  }

  .title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 400;
    text-align: center;

    @media (max-width: $bigMobile) {
      font-size: 20px;
    }
  }
}

.note {
  margin-top: 16px;
  font-size: 12px;
  color: $black;
  text-align: center;
}