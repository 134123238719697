@import 'src/variables';
@import 'src/mixins';

.realDealCard {
  text-align: center;
}

.trdIframeWrapper {
  height: 0;

  -webkit-transition: height 0.2s linear;
  -moz-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  transition: height 0.2s linear;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  iframe {
    border: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.iframeRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &:first-of-type {
    border-bottom: 1px solid $lightgray;
    padding: 0 0 30px 0;
  }
}
