@import 'src/variables';

.aboutWrapper {
  position: relative;
  margin: 2.375rem 0 0 0;
}
.aboutInner {
  width: 63%;
  padding: 2.625rem 7.875rem 1.25rem 0;
  position: relative;
  h2 {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    margin: 0 0 1.25rem 0;
  }
  > div {
    position: relative;
    z-index: 2;
  }
  &:before {
    content: '';
    position: absolute;
    background: $white;
    right: 0;
    top: 0;
    bottom: 0;
    left: -100%;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    z-index: 1;
  }
}

.showMoreBtn {
  display: none;
}

@media (max-width: $smallScreen) {
  .aboutWrapper {
  }
  .aboutInner {
    width: 100%;
    padding: 2.5rem;
    &::before {
      left: 0;
    }
  }
}
@media (max-width: $smallTablet) {
  .aboutWrapper {
    margin: 0;
    > div {
      padding: 0;
    }
  }
  .aboutInner {
    padding: 0 1.875rem 1.5rem 1.875rem;
    &:before {
      box-shadow: none;
    }
    h2 {
      font-size: 1.25rem;
    }
  }
  .textWrapper {
    max-height: 4.5rem;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    &.visible {
      max-height: initial;
      line-height: 1.5;
      display: block;
      -webkit-box-orient: initial;
      -webkit-line-clamp: initial;
      overflow: initial;
    }
  }
  .showMoreBtn {
    display: block;
    background: none;
    border: none;
    color: $pink;
    padding: 0.375rem 1rem;
    cursor: pointer;
    margin: 0.625rem auto 0 auto;
  }
}
