@import 'src/variables';
@import 'src/mixins';

.textarea:global(.ant-input) {
  width: 100%;
  max-width: 28.75rem;
  padding: 1.063rem;
  border: none !important;
  font-size: 1rem;
  resize: vertical;
  background: #ffffff;
  border: 2px solid $altgray;
  box-shadow: $stdShadow;
  border-radius: 4px;
  margin-top: 1.25rem;
}
