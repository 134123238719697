@import 'src/variables';

.wrapper {
  :global(.ant-modal) {
    @media (max-width: $tablet) {
      width: 16.375rem !important;

      :global(.ant-modal-content) {
        border-radius: 1rem;
         box-shadow: none;
      }
    }
  }
}
