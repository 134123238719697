@import 'src/variables';
@import 'src/mixins';

.container {
  position: absolute;
  top: 0;
  right: -11.875rem;
  display: flex;
  flex-direction: column;
  width: 11.875rem;
  padding: 30px 1rem;

  border-radius: 0 0 0 25px;
  z-index: 100;

  box-shadow: 0 0.25rem 1rem rgba(72, 78, 85, 0.15);
  background: white;

  @include transition(transform 0.3s linear);

  &.active {
    transform: translateX(-100%);
  }

  .settingsIcon {
    top: 0;
  }
}

.optionsContainer {
  padding-left: 6px;
  display: flex;
  flex-direction: column;

  h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: $pink;
  }
}

.line {
  margin: 18px 0;
  width: 100%;
  height: 1px;
  background: #9D9D9D;
}

.settingsIcon {
  position: absolute;
  left: -4.063rem;
  top: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.063rem;
  height: 4.188rem;
  background: #ffffff;
  border: 1px solid #cdd3e1;
  box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
  border-radius: 50% 0 0 50%;
  cursor: pointer;
}

// todo: improve option styles (works bad with checkbox)
.option {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 1rem 0;

  font-weight: bold;
  font-size: 1rem;
  border-bottom: 1px solid #ededef;
  cursor: pointer;

  p {
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
  }

  &.mobileColor {
    color: $pink;
  }

  &:first-of-type {
    padding-top: 0.5rem;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}
