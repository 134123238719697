@import 'src/variables';

.warningModalBody {
  padding-bottom: 4.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $tablet) {
    padding-bottom: 0;
    height: auto;
    justify-content: flex-start;

    border-radius: 1rem;
  }

  h4 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    text-align: center;
    font-size: 1rem;
  }
}

.warningModalBody {
  @media (max-width: $tablet) {
    h4 {
      margin-top: 1rem;
      margin-bottom: 2rem;

      font-size: 1rem;
    }

    img {
      width: 8.125rem;
    }
  }
}
