@import 'src/variables';

.successModalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $tablet) {
    height: auto;
    justify-content: flex-start;

    border-radius: 1rem;
  }

  h4 {
    font-size: 2rem;
    font-weight: bold;

    @media (max-width: $tablet) {
      margin-top: 1rem;
      margin-bottom: 2rem;

      font-size: 1rem;
      text-align: center;
    }
  }
}
