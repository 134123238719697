@import 'src/variables';

.wrapper {
  &:global(.ant-collapse) {
    border-radius: 10px;
  }

  :global(.ant-collapse-header), :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }

  :global(.ant-collapse-header-text) {
    width: 100%;
  }

  box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
}

.header {
  padding: 0 20px 0 40px;
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  cursor: pointer;

  transition: border-radius;
  transition-delay: 100ms;

  &.opened {
    border-radius: 10px 10px 0 0;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;

    h4 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      color: $black;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    address {
      margin: 0;
      font-size: 14px;
      color: $black;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .proTourLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 82px;
    height: 23px;

    background-color: $pink;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    color: $white;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.18);

    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 13px;
      margin-right: 5px;

      background: url('../../../../../assets/icons/box.svg') center no-repeat;
    }
  }

  .price {
    margin-right: 25px;
    max-width: 90px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
  }
  
  .historyAndPaymentButton {
    width: 235px;
    height: 100%;
    display: flex;
    align-items: center;

    &.submenuOpeningAnimation {
      animation: subMenuOpening 300ms forwards;
    }

    &.submenuClosingAnimation {
      animation: subMenuClosing 300ms forwards;
    }

    .line {
      width: 1px;
      height: 50px;
      background-color: #D9D9D9;
    }

    button {
      position: relative;
      top: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      height: 100%;
      font-size: 11px;
      color: $black;
      border: none;
      background: inherit;
      cursor: pointer;

      &:after {
        margin-top: 8px;
        content: '';
        display: block;
        background: url('../icons/triangle-flat.svg');
        width: 32px;
        height: 7px;
      }
    }
  }
}

.contentContainer {
  height: 278px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 10px 10px;
  background-color: white;

  .line {
    height: 1px;
    width: 100%;
    background-color: #9D9D9D;
  }

  .content {
    display: flex;
    overflow: hidden;

    .paymentMethod, .billingHistory {
      width: 50%;
      padding-top: 18px;
    }
  }

  .closeButton {
    margin-top: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    border: none;
    background: inherit;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 32px;
      height: 7px;
      background: url('../icons/triangle-flat.svg');
      transform: rotate(180deg);
    }
  }
}

@keyframes subMenuOpening {
  from {
    width: 235px;
    visibility: hidden;
  }

  to {
    width: 0;
    visibility: hidden;
    position: absolute;
  }
}

@keyframes subMenuClosing {
  0% {
    width: 0;
    visibility: hidden;
  }

  99% {
    width: 235px;
    visibility: hidden;
  }

  100% {
    width: 235px;
    visibility: visible;
  }
}