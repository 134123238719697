.fakeSelectWrapper {
  position: relative;
  input {
    min-height: 60px;
  }
  img {
    position: absolute;
    right: 16px;
    top: 25px;
    height: 0.5rem;
    pointer-events: none;
  }
}

.fakeSelectWrapper + :global(.ant-select) {
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0px !important;
  padding: 0px !important;
  position: absolute;
  top: 0;
  left: 0;
  > div {
    visibility: hidden !important;
    opacity: 0 !important;
    padding: 0px !important;
  }
}
