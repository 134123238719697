@import 'src/variables';
@import 'src/mixins';

.popupInner {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  margin: 0 0 20px 0;
}

.copyLinkWrapper {
  @extend .popupInner;
  padding: 32px 50px;
  display: flex;
  align-items: center;
  input {
    margin: 0 0 0 3.125rem;
    width: calc(100% - 206px - 50px);
  }
  button {
    min-width: 12.875rem;
  }
}
.shareSocialWrapper {
  @extend .popupInner;
  padding: 2.5rem 2rem;
}

.iframeWrapper {
  @extend .popupInner;
  padding: 2.25rem 1.25rem;
}

.basicBtn {
  background: $white;
  border: 2px solid $pink;
  border-radius: 50px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  @include transition(256ms background-color, 256ms color);
  cursor: pointer;
  padding: 0.875rem 1.5rem;
  text-transform: uppercase;
  &:hover {
    background: $pink;
    color: $white;
  }
}

.codeWrapper {
  background: #ffffff;
  border: 1px solid #dadada;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  &:focus {
    outline-color: $pink;
  }
}

.shareSocialWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.shareSocialOuter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shareSocialInner {
  display: flex;
}
.socialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $pink;
  border-radius: 50%;
  margin: 0 0.75rem;
  min-width: 3.375rem;
  min-height: 3.375rem;
  background: $white;
  @include transition(256ms background-color);
  svg {
    color: #737683;
    @include transition(256ms color);
  }
  &:hover {
    background: $pink;
    svg {
      color: $white;
    }
  }
}
.theRealDealBtnContainerMobile {
  display: none;

  @media (max-width: $tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 90px;

    border-radius: 10px;
    background-color: $white;
  }
}

.theRealDealBtnContainerDesktop {
  margin-left: 56px;

  @media (max-width: $tablet) {
    display: none;
  }
}

.theRealDealBtn {
  border-radius: 6.25rem;
  max-width: 18.25rem;
  width: 100%;
  padding: 0.85rem 1.25rem;
  @include transition(256ms background-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    @include transition(256ms color);
    max-width: 165px;
    height: auto;
    color: #fff;
  }
}

.trdIframeWrapper {
  height: 0;

  -webkit-transition: height 0.2s linear;
  -moz-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  transition: height 0.2s linear;
  width: 100%;
  overflow: auto;
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}
.trdIframeVisible {
  height: 50vh;
}

.iframeRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &:first-of-type {
    border-bottom: 1px solid $lightgray;
    padding: 0 0 30px 0;
  }
}

.iframeViewerText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 0 1.875rem;
  h6 {
    font-size: 1.563rem;
    line-height: 1.75rem;
    margin: 0px 0 1rem 0;
    text-align: center;
  }
  p {
    font-size: 1rem;
    color: $pink;
    margin: 0 0 1.25rem 0;
    img {
      margin: 0 0 0 0.375rem;
      cursor: pointer;
    }
  }
  .basicBtn {
    max-width: 15.625rem;
    min-width: 15.625rem;
    padding: 0.875rem 1rem;
    margin: 0 auto;
    display: block;
    width: 100%;
  }
}
.iframeViewerInputs {
  width: 100%;
  padding: 0 1.875rem 0 2.5rem;
  .textarea {
    width: 100%;
    min-height: 7.5rem;
  }
}

.textarea {
  &:focus {
    outline-color: $pink;
  }
}

.inputsOuter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0 0 0;
  p {
    margin: 0;
    opacity: 0.5;
  }
}

.inputWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1.875rem 0 0;
  label {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
  }
  input {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    width: 5rem;
    height: 2.25rem;
    border: 1px solid $lightgray;
  }
}

.popoverWrapper {
  h5 {
    font-size: 1.25rem;
    margin: 0.5rem 0 1rem 0;
    font-weight: 700;
  }
  p {
    font-size: 1rem;
    margin: 0 0 1rem 0;
  }
}

.socialIcon {
  .google,
  .twitter,
  .linkedin {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }

  .google {
    background-image: url('../../../assets/icons/google-grey.svg');
  }

  .twitter {
    background-image: url('../../../assets/icons/twitter-grey.svg');
  }

  .linkedin {
    background-image: url('../../../assets/icons/linkedin-grey.svg');
  }
  &:hover {
    .facebook {
      background-image: url('../../../assets/icons/facebook-white.svg');
    }
    .google {
      background-image: url('../../../assets/icons/google-white.svg');
    }
    .linkedin {
      background-image: url('../../../assets/icons/linkedin-white.svg');
    }
    .twitter {
      background-image: url('../../../assets/icons/twitter-white.svg');
    }
  }
}

.bottomRow {
  padding: 2rem 1.875rem 0 1.875rem;
  align-items: center;
  .basicBtn {
    max-width: 250px;
    width: 100%;
    padding: 0.875rem 1rem;
  }
  .logoWrap {
    margin: 0 1.875rem;
    min-width: 4.5rem;
    min-height: 4.5rem;
    border-radius: 50%;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.14);
  }
  .textarea {
    width: 100%;
    min-height: 4.875rem;
    max-width: 26.875rem;
  }
}

:global(.shareTourModal) {
  :global(.ant-modal-content) {
    background: none;
    box-shadow: none;
    border-radius: 0px;
  }
  :global(.ant-modal-close) {
    right: -30px;
    svg {
      height: 40px;
      width: 40px;
    }
  }
}

:global(.howtoPopover) {
  :global(.ant-popover-inner) {
    border-radius: 0.938rem;
    padding: 2.5rem;
    max-width: 26.875rem;
  }
  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}
:global(.whiteTooltip) {
  :global(.ant-tooltip-inner) {
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 7.125rem;
    border-radius: 0.938rem;
    font-size: 1rem;
    text-align: center;
    color: $black;
    padding: 1rem;
  }
  :global(.ant-tooltip-arrow-content) {
    background-color: $white;
  }
  :global(.ant-tooltip-arrow-content) {
    display: none;
  }
}

:global(.howToModal) {
  :global(.ant-modal) {
    width: 431px !important;
    border-radius: 15px;
    :global(.ant-modal-content) {
      border-radius: 15px;
    }
  }
}

@media (max-width: $smallScreen) {
  :global(.shareTourModal) {
    :global(.ant-modal-close) {
      right: 0;
    }
  }
}

@media (max-width: 900px) {
  .copyLinkWrapper {
  }
  .iframeWrapper {
    padding: 2.25rem 1rem;
    // padding: 1.875rem;
    // display: none;
    .basicBtn {
      padding: 0.875rem 0.5rem;
    }
    .iframeViewerText {
      padding: 0;
    }
    .iframeViewerInputs {
      padding: 0 0 0 1.875rem;
    }
  }
  .inputsOuter {
    p {
      display: none;
    }
  }
  .bottomRow {
    padding: 2.25rem 0 0 0;
  }
}

@media (max-width: $tablet) {
  :global(.shareTourModal) {
    :global(.ant-modal) {
      width: initial !important;
      margin-top: 1rem;
    }
  }
  .copyLinkWrapper {
    justify-content: center;
    padding: 1rem;
    input {
      display: none;
    }
  }
  .shareSocialWrapper {
    flex-direction: column;
    .therealdealbtn {
      margin: 2rem 0 0 0;
    }
  }
  .iframeWrapper {
    padding: 1.25rem 1.875rem 1.875rem 1.875rem;
  }
  .iframeRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .iframeViewerText {
    h6 {
      font-size: 1.125rem;
      br {
        display: none;
      }
    }
    .basicBtn {
      display: none;
    }
  }
  .inputsOuter {
    p {
      display: block;
    }
  }
  .bottomRow {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .basicBtn {
      width: 100%;
      order: 3;
      margin: 1.375rem auto 0 auto;
    }
    .logoWrap {
      order: 2;
      margin: 0 0 0 20px;
    }
    .textarea {
      order: 1;
      width: calc(100% - 72px - 20px);
    }
  }
}

@media (max-width: $bigMobile) {
  .iframeWrapper {
    .iframeViewerInputs {
      padding: 0;
    }
  }
  .textarea {
    font-size: 0.875rem;
  }
  .inputsOuter {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    p {
      margin: 0px 0 0 20px;
      position: relative;
      top: 20px;
      max-width: 100px;
    }
  }
  .inputWrap {
    margin: 0 0 0.625rem 0;
    &:nth-of-type(2) {
      order: 3;
    }
    label {
      min-width: 3.75rem;
    }
  }
}

@media (max-width: $smallMobile) {
  .inputsOuter {
    flex-direction: column;
    position: relative;
    p {
      position: static;
      order: 4;
      max-width: initial;
      margin: 0;
    }
  }
  .shareSocialWrapper {
    padding: 1rem;
  }
  .therealdealbtn svg {
    max-width: 120px;
  }
  .shareSocialInner {
    display: flex;
  }
  .socialIcon {
    // width: 50%;
    margin: 0.5rem;
  }
  .iframeWrapper {
    .iframeViewerInputs {
      padding: 0;
    }
  }
  .basicBtn {
    font-size: 0.875rem;
  }
  .bottomRow {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .basicBtn {
      width: 100%;
      order: 3;
      margin: 0 auto 0 auto;
    }
    .logoWrap {
      order: 2;
      margin: 1.25rem auto;
    }
    .textarea {
      order: 1;
      width: 100%;
    }
  }
}
