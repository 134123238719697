@import 'src/variables';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    position: absolute;
    left: 25px;
    display: block;
    width: 23px;
    height: 24px;
    background: url('../../../assets/icons/search.svg') no-repeat center;
    z-index: 2;
    content: '';

    @media (max-width: $bigMobile) {
      left: 10px;
    }
  }
}

.bigSize {
  .searchInput:global(.ant-select):global(.ant-select) {
    :global(.ant-select-selector) {
      min-height: 3.75rem;
    }
  }

  :global(.ant-select-dropdown),
  .noResultsFound {
    min-height: 3.75rem;
  }
}

.searchInput:global(.ant-select):global(.ant-select) {
  width: 100%;
  border-radius: 30px;
  box-shadow: 0 0 1.25rem 2px #80808054;
  transition: 256ms border-radius;

  :global(.ant-select-selector) {
    width: 100%;
    padding: 0 1.25rem 0 2.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 30px;
  }

  :global(.ant-select-selection-search) {
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 1.25rem 0 3.75rem;
  }

  :global(.ant-select-selection-item) {
    display: flex;
    align-items: center;
    padding: 0;

    @media (max-width: $bigMobile) {
      font-size: 12px;
    }
  }

  :global(.ant-select-selector) {
    border-radius: 30px;
  }

  :global(.ant-select-selection-placeholder) {
    display: flex;
    align-items: center;
    align-content: center;
  }

  &.notFoundVisible {
    :global(.ant-select-selector) {
      border-radius: 30px 30px 0 0;
    }
  }

  &:global(.ant-select-focused) {
    border-radius: 30px 30px 0 0;

    :global(.ant-select-selector) {
      border-radius: 30px 30px 0 0;
    }
  }

  :global(.ant-select-selection-search) {
    padding: 0;
  }

  :global(.ant-input) {
    border: none;

    @media (max-width: $bigMobile) {
      padding-left: 0;
      padding-right: 0;
      font-size: 12px;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.dropdownSelect:global(.ant-select-dropdown),
.noResultsFound {
  top: 100%;
  width: 100%;
  padding: 0 1.25rem 1rem 3.2rem;
  border-radius: 0 0 30px 30px;
  background: $white;
  font-size: 1rem;
  align-items: center;
  border-top: 1px solid #dadada;
  z-index: 100;

  :global(.ant-select-item) {
    font-size: 12px;
  }

  @media (max-width: $bigMobile) {
    padding-left: 2rem;
    padding-right: 1rem;
    font-size: 12px;
  }
}

.noResultsFound {
  position: absolute;
  display: none;

  &.notFoundVisible {
    display: flex;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.resetBtn {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
  transform: rotate(45deg);
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.6;
  background: transparent;
  border: none;

  @media (max-width: $bigMobile) {
    right: 0;
  }
}
