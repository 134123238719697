@import 'src/variables';
@import 'src/mixins';

.container {
  display: flex;
  align-items: center;
  background-color: $navGray;

  @media (max-width: $tablet) {
    display: none;
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 0 !important;
  }

  :global(.ant-tabs-tab) {
    z-index: 2;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #9D9D9D;
    border-radius: 8px;

    @media (max-width: $smallScreen) {
      padding: 0.8rem 1rem;
    }

    :global(.ant-tabs-tab-btn):focus {
      color: $pink;
    }

    &:hover {
      color: $pink;
    }

    @media (max-width: $tablet) {
      padding: 0.7rem 1rem;
    }

    @media (max-width: $mobile) {
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
    }

    @media (max-width: $smallMobile) {
      padding: 0.5rem 0.8rem;
    }
  }

  :global(.ant-tabs-nav-wrap) {
    padding: 0.5rem 1rem;
  }

  :global(.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn) {
    color: $pink;
  }

  :global(.ant-tabs-ink-bar-animated) {
    height: 100% !important;
    border-radius: 0.5rem;
    background-color: $white;
    box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
  }
}

.tabsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  width: 100%;
  padding: 2px 14px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
  color: $pink;

  @media (max-width: $smallScreen) {
    max-width: 200px;
  }

  .planInfo {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 12px;
    }

    .planName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;

      &:after {
        content: '';
        display: block;
        width: 20px;
        height: 9px;
        background: url('../../../assets/icons/triangle.svg') no-repeat center;
        transform: rotate(-90deg);
      }
    }
  }

  .tip:before {
    content: '';
    width: 11px;
    height: 11px;
    display: block;
    background: url('../../../assets/icons/info-gray.svg') no-repeat center;
    background-size: contain;
  }
}
