@import 'src/variables';

.select {
  max-width: 366px;
  width: 100%;
  margin: 0;

  :global(.ant-select):global(.ant-select) {
    border-radius: 30px;

    :global(.ant-select-selector) {
      min-height: unset;
      border-radius: 30px;
    }
  }
}
