@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

html {
  /*  hide scroll  */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  max-width: 100%;
  /* to fix width when a popup is opened */
  width: 100% !important;
  margin: 0 auto;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bodyWrapper {
  /* max-width: 90rem; */
  /* to fix width when a popup is opened */
  width: 100% !important;
  margin: 0 auto;
  min-height: 100vh;
}

.basicMaxWidth {
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
}

/*  hide scroll  */
body::-webkit-scrollbar {
  display: none; /* Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.basicContainer {
  width: 100%;
  max-width: 1182px;
  padding: 0 20px;
  margin: 0 auto;
}

.sticky {
  position: sticky  !important;
  top: 20px  !important;

  /* left: 0  !important; */
  transform: none !important;
  margin: 0 0 76px 0;
}

@media (max-width: 39.375rem) {
  .basicContainer {
    overflow-x: hidden;
  }
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: initial;
}


.overflowHidden {
  overflow: hidden !important;
}

::-webkit-scrollbar {
   width: 4px;
}

::-webkit-scrollbar-track {
   border-radius: 4px;
}

::-webkit-scrollbar-thumb {
   background-color: #C4C4C4;
   border-radius: 4px;
}