@import 'src/variables';

.container {
  position: relative;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;

    h5 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      color: $black;
    }

    .backButton {
      margin: 0;
      padding: 3px 6px;
      border: none;
      background: transparent;
      cursor: pointer;
      opacity: 1;
      transition: opacity 300ms;

      &.hide {
        opacity: 0;
        cursor: default;
      }
    }
  }
}

.list {
  position: absolute;
  width: 100%;
  opacity: 1;
  transition: opacity 300ms;
  z-index: 2;

  &.hide {
    z-index: 1;
    opacity: 0;
  }

  .historyItems {
    padding-right: 20px;
    max-height: 174px;
    height: 100%;
    overflow: auto;
  }

  .seeMoreButton {
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: $pink;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
  }
}

.details {
  position: absolute;
  width: 100%;
  opacity: 1;
  transition: opacity 300ms;
  z-index: 2;

  &.hide {
    z-index: 1;
    opacity: 0;
  }

  .detailsItem {
    margin: 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #7B7B7B;
    }

    .cost {
      font-size: 18px;
      color: $black;
    }
  }

  .line {
    height: 1px;
    width: 100%;
    background-color: #C4C4C4;
  }
}

.historyItemButton {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: flex-end;
  height: 58px;
  background: inherit;
  border: none;
  border-bottom: 1px solid #9D9D9D;
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span:first-child {
      font-size: 18px;
      color: $black;
    }

    span:last-child {
      font-size: 12px;
      color: #9D9D9D;
    }
  }

  .price {
    margin-left: auto;
    position: relative;
    top: -20px;
    font-size: 18px;
    color: $black;
  }

  .angleIcon {
    margin-left: 30px;
    align-self: center;
  }
}
