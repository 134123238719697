@import 'src/variables';

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;

  h1 {
    color: $black;
    font-size: 52px;
    font-weight: 300;
    text-align: center;

    @media (max-width: $bigMobile) {
      font-size: 32px;
    }
  }

  p {
    max-width: 870px;
    font-size: 24px;
    color: #7B7B7B;
    text-align: center;

    @media (max-width: $bigMobile) {
      font-size: 14px;
    }

    span {
      color: $pink;
    }
  }

  .modalButton {
    border: none;
    background: transparent;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }

    img {
      margin-top: 30px;
      max-width: 380px;
      width: 100%;
      height: 100%;
    }
  }
}

.modalText {
  font-size: 18px;
  text-align: center;

  @media (max-width: $tablet) {
    font-size: 14px;
  }
}