@import 'src/variables';

.modal {
  max-width: 600px;
  width: 95% !important;
  border-radius: 16px;

  :global(.ant-modal-content) {
    border-radius: 16px;
    box-shadow: none !important;
  }
}

.container {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }
}

.button {
  margin-bottom: 20px;
  text-transform: uppercase;

  &:last-of-type {
    margin-bottom: 0;
  }
}

