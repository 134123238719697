@import 'src/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h5 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: $black;
  }

  .paymentList {
    padding-right: 20px;
    max-width: 400px;
    max-height: 174px;
    width: 100%;
    height: 100%;
    overflow: auto;

    :global(.ant-radio-wrapper) {
      margin-right: 0;
      display: flex;
      height: 58px;
      width: 100%;

      font-size: 17px;
      color: $black;
      border-bottom: 1px solid #9D9D9D;

      &:after {
        display: none;
      }

      :global(.ant-radio) + span {
        width: 100%;
      }

      .paymentItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .editCardButton {
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: $pink;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
  }
}