@import 'src/variables';

@mixin active {
  div {
    color: $white;
    &:before {
      box-shadow: 0 0 0 1.875rem $pink;
      border-radius: 50%;
      display: block;
      opacity: 1;
    }
  }
  .name {
    color: $pink;
  }
}

.userButton {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  div {
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
    color: $pink;
    font-weight: bold;
    font-size: 1.563rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      height: 1px;
      width: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 0 0 $pink;
      border-radius: 50%;
      background: $pink;
      transition: box-shadow 0.3s;
      opacity: 0;
      z-index: 1;
    }
    span {
      z-index: 2;
      position: relative;
    }
  }

  @media (min-width: $bigMobile) {
    &:hover {
      @include active;
    }
  }

  &.active {
    @include active;
  }
}

.name {
  margin: 0 1.563rem 0 0;
  color: $gray;
  font-weight: bold;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
