.feedbackModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h5 {
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1.875rem;
  }
}

.modal:global(.ant-modal) {
  width: 520px !important;
}
