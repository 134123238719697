@import 'src/variables';
@import 'src/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #dcdcdc;
  //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  .overlay {
    left: 0;
    top: 0;
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(187, 187, 187, 1) 100%
    );
    overflow: hidden;

    // cursor: pointer;

    // .playIcon {
    //   // to avoid shaking on hover when half of the iframe is visible
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 6.25rem;
    //   height: 6.25rem;

    //   color: $white;
    //   font-size: 5.4rem;

    //   @include transition(font-size 0.1s linear);
    // }

    // &:hover {
    //   .playIcon {
    //     font-size: 5.9rem;
    //   }

    .beginTourButton {
      width: 250px;
      cursor: pointer;
    }
  }

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
  }
}
