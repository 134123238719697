@import 'src/variables';

.container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  background: url('../../../../assets/images/payment/polygons.png') no-repeat top right;

  @media (max-width: $tablet) {
    padding-top: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: 300;
  }
}

.toursContainer {
  position: relative;
  left: -15px;
  top: -15px;
  padding: 15px 20px 15px 15px;
  width: 100%;
  box-sizing: border-box;
  // 90 - footer, 86 - header, 66 - second header, 84 - title with paddings
  max-height: calc(100vh - 90px - 86px - 66px - 84px);
  overflow: auto;

  & > div {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.empty {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 354px;

  font-size: 24px;
  color: #7B7B7B;

  span {
    text-align: center;
  }
}
