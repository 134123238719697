@import './variables';
@import './mixins';

.ant-form-large .ant-form-item-label > label {
  height: initial;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 4px 0;
}

.ant-select-selector,
.ant-input-affix-wrapper,
.ant-input {
  border: 2px solid #f8f8f8;
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 14px;
}

.ant-pagination.mini {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2.25rem 0 0 0;
}

.ant-pagination-item {
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 100% !important;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(0.3s linear);
  cursor: pointer;
  margin: 0 0.313rem 0 0 !important;
  border: none !important;
  &:focus-visible,
  &:hover {
    a {
      color: $pink !important;
    }
  }
}

.ant-pagination-item.ant-pagination-item-active {
  background: $pink;
  border: none;
  a {
    color: $white !important;
  }
  &:hover {
    a {
      color: $white !important;
    }
  }
}

.ant-pagination-next,
.ant-pagination-prev {
  display: flex;
  align-items: center;
  span {
    font-weight: bold;
    font-size: 0.875rem;
    color: $pink;
  }
  img {
    width: 1.188rem;
  }
}

.ant-pagination-disabled {
  opacity: 0.5;
}

.ant-pagination-next {
  margin: 0 0 0 16px !important;
  span {
    margin: 0 10px 0 0;
  }
}
.ant-pagination-prev {
  margin: 0 16px 0 0 !important;
  span {
    margin: 0 0 0 10px;
  }
}

svg.recharts-surface {
  overflow: visible;
}

.ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.ant-radio {
  top: 0;
}

.bodyWrapper {
  /*
  background-size: contain;
  max-width: 100%;
  background-position-y: 65%;
  // background-image: url('app/assets/images/layout/Background_Elements.svg');
  background-repeat: repeat-y;
  // background-position-y: ;
  */
}

.ant-progress-text {
  color: $pink !important;
}
