.container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}