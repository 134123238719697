@import 'src/variables';
@import 'src/mixins';

.infoWrapper {
  margin: 5.188rem 0 0 0;
  width: 63%;
}
.infoWrapper_m {
  display: none;
}

.header {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 0.5rem;
}

.infoItem {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  b {
    margin: 0 0.5rem 0 0;
  }
}
.statsWrapper {
  display: flex;
  margin: 1.625rem 0 2rem 0;
  align-items: center;
  a {
    margin: 0 0 0 auto;
  }
}
.editProfileButton {
  margin: 0;
  font-weight: 800;
  padding: 1rem 1.875rem;
  text-transform: uppercase;
  background: #ffffff;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
  color: $pink;
  border: none;
  filter: drop-shadow(0px 4px 15px rgba(72, 78, 85, 0.15));
  cursor: pointer;
  @include transition(0.3s linear);
  &:hover {
    color: $white;
    background: $pink;
  }
}

.statsItem {
  border-left: 1px solid $lightgray;
  padding: 0 1.5rem;
  &:first-of-type {
    border-left: none;
    padding: 0 1.5rem 0 0;
  }
  b {
    font-size: 1.25rem;
    margin: 0 0.75rem 0 0;
  }
  span {
    font-size: 1rem;
  }
}

@media (max-width: $tablet) {
  .infoWrapper {
    margin: 2rem 0 0 0;
  }
}
@media (max-width: $smallScreen) {
  .infoWrapper {
    width: 100%;
    padding: 0 1rem;
  }
}

@media (max-width: $smallTablet) {
  .headerWrapper {
    background: #fff;
    position: relative;
    z-index: 2;
  }
  .infoWrapper {
    display: none;
  }
  .infoWrapper_m {
    display: block;
    padding: 1.5rem 0.625rem 0 0.625rem;
  }
  .statsWrapper {
    position: relative;
    padding: 24px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after,
    &:before {
      content: '';
      position: absolute;
      background: $lightgray;
      left: 0px;
      right: 0px;
      height: 1px;
    }
    &::after {
      bottom: 0;
    }
    &::before {
      top: 0;
    }
  }
  .statsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    b {
      margin: 0;
    }
  }
  .location {
    font-size: 1rem;
    margin: 1rem 0 2rem 0px;
    img {
      margin: 0 0.5rem 0 0;
    }
  }
  .headerIconWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .header {
    margin: 0;
    line-height: 1.2;
    padding: 0 1rem 0 0;
    display: block;
    width: 100%;
  }
  .editIcon {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 0px;
    padding: 0.5rem;
    z-index: 5;
  }
}
