@import 'src/variables';

.radio {
  font-size: 1rem;

  @media (max-width: $tablet) {
    font-size: 0.875rem;

    font-weight: 500;
  }

  :global(.ant-radio-checked) {
    &:after {
      border-color: $pink;
    }

    :global(.ant-radio-inner) {
      border: 2px solid $pink !important;
    }
  }

  :global(.ant-radio-inner) {
    width: 1.375rem;
    height: 1.375rem;
    border: 2px solid $radioButtonBorder;

    &:after {
      margin-top: -6px;
      margin-left: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 0.75rem;
      height: 0.75rem;
      transform: scale(1) !important;

      background-color: $pink;
    }
  }
}
