@import 'src/variables';

.modalContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 50px;
  &.modalForm {
    width: 370px;
    padding: 50px 0px;
    margin: 0 auto;

    @media (max-width: $tablet) {
      padding: 1.25rem 0;
      width: auto;
    }

    h4 {
      margin-bottom: 2.25rem;

      @media (max-width: $tablet) {
        margin-bottom: 0.75rem;
        font-size: 1.125rem;
      }
    }

    .form {
      width: 100%;
    }
  }
  h4 {
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
    font-weight: 600;
    text-align: center;
    max-width: 330px;
  }
}

.modalButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 12px 26px;
    min-width: 85px;
    margin: 0 1rem 0 0;
    font-size: 0.875rem;
    text-transform: uppercase;
    &:last-of-type {
      margin: 0;
    }
  }
}

.error {
  color: $error;
}
