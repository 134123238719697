@import 'src/variables';

.wrapper {
  background-color: $white;
  margin-top: auto;
}

.container {
  position: relative;

  min-height: 5.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: 0 auto;

  @media (max-width: $smallScreen) {
    justify-content: space-around;
  }

  @media (max-width: $tablet) {
    display: none;
  }

  a {
    text-decoration: none;
    color: $black;
  }

  .links {
    display: flex;
    padding-top: 0.4rem;
    p:hover {
      cursor: default;
    }

    a {
      margin-left: 1.875rem;
    }
  }

  .socialLinks {
    display: flex;
    align-items: center;

    a {
      margin-right: 1rem;
      width: 1.5rem;
      height: 1.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      fill: #8f8e8e;

      &:hover {
        fill: $pink;
      }
    }
  }
}
