@import 'src/variables';

.modal {
  max-width: 600px !important;
  width: 95% !important;
  border-radius: 16px;

  :global(.ant-modal-content) {
    border-radius: 16px;
  }
}

.container {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 30px;
  }

  img.downgrade {
    transform: rotate(180deg);
  }

  .title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    @media (max-width: $bigMobile) {
      font-size: 20px;
    }
  }

  p {
    text-align: center;
  }
}

.shareTourButton {
  margin-top: 12px;
  padding: 14px 22px;
  height: 50px;
  font-size: 18px;
  font-weight: 700;

  &:before {
    content: '';
    margin-right: 16px;
    display: block;
    width: 24px;
    height: 24px;
    background: url('../../../../../../assets/icons/share-white.svg') no-repeat center;
  }
}
