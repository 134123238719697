@import 'src/variables';

.container {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  @media (max-width: $tablet) {
    display: none;
  }
}

.sortLabel {
  font-size: 0.875rem;
  margin: 0 3px 0 0;
}

.selectedValueWrap {
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  span {
    font-weight: bold;
  }
  img {
    margin: 0 0 0 8px;
  }
}

.listWrapper {
  background: $white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0.5rem 0;
}

.listItem {
  font-weight: bold;
  font-size: 14px;
  padding: 1rem 1.5rem;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    background: $lightgray;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
  }
  &:first-of-type {
    &:before {
      content: none;
    }
  }
  &:hover {
    background: $lightPink;
  }
}

//todo: temp solution
.sideSortingMenu {
  display: none;
  right: -13.2rem;

  @media (max-width: $smallTablet) {
    display: block;
  }
}