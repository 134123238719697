@import 'src/variables';

.outer {
  margin: 5rem 0 0 0;
  padding: 0 0 5rem 0;
}

.featuredWrapper {
}

.mobile {
  display: none;
}

.featuredHeader {
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0 0 1.125rem 0;
}

.featuredInner {
  border-radius: 10px;
  width: 100%;
  background: $white;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.05);
  justify-content: space-evenly;
}
.featuredThumbnails {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.featuredImage {
  max-height: 18.75rem;
  width: 24%;
}

.featuredTextWrapper {
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featuredTextInner {
  display: flex;
  align-items: center;
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0 1.25rem 0 0;
    margin: 0;
  }
  p {
    padding: 0.5rem 1.375rem;
    font-size: 0.875rem;
    margin: 0;
    border-left: 1px solid $lightgray;
  }
}

.featuredStatsInner {
  display: flex;
  align-items: center;
  .viewsCount {
    padding: 0.375rem 1.25rem;
    font-size: 1.25rem;
    border-right: 1px solid $lightgray;
    display: flex;
    align-items: center;
    img {
      margin: 0 0 0 0.5rem;
    }
  }
}

.shareButton {
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 0 0 18px;
}

@media (max-width: $smallScreen) {
  .featuredTextWrapper {
    position: relative;
  }
  .featuredTextInner {
    flex-wrap: wrap;
    width: 100%;
    h2 {
      width: 50%;
      order: 1;
    }
    p {
      border: none;
    }
    .textAddress {
      order: 3;
      padding: 0.5rem 0;
      width: 50%;
    }
    .textDate {
      order: 2;
      width: 50%;
      text-align: right;
      padding: 0.5rem 0;
    }
  }

  .featuredStatsInner {
    position: absolute;
    bottom: 0.5rem;
    right: 1.25rem;
  }
}

@media (max-width: $smallTablet) {
  .outer {
    margin: 1.5rem 0 3rem 0;
    :global(.basicContainer) {
      padding: 0 1.875rem 0 1.875rem;
    }
  }
  .featuredInner {
    display: none;
  }
  .mobile {
    display: block;
  }
}
