@import 'src/variables';
@import 'src/mixins';

.stickyMobile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 4rem;
  display: flex;
  justify-content: flex-start;

  background-color: inherit;
  z-index: 20;

  h1 {
    position: relative;
    left: -32px;
    margin: auto;

    font-size: 28px;
    font-weight: 400;
  }
}

.button {
  width: 4rem;
  height: 4rem;

  border: none;
  background-color: inherit;
}

.mobilePlaceholder {
  height: 4rem;
}

.menuHeaderContainer {
  background-color: inherit;
}