@import 'src/variables';

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-size: contain;

  // contain: paint;
}
.headerContainer {
  padding: 30px 60px 20px;
  position: sticky;
  top: 64px;
  height: 89px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 1;

  @media (max-width: $smallScreen) {
    padding: 18px 10px 20px;
  }

  @media (max-width: $tablet) {
    top: 64px;
    justify-content: space-between;
    padding-right: 80px;
  }

  .header {
    flex-shrink: 0;
    font-size: 36px;
    font-weight: 300;
    color: $black;
    margin: 0;

    @media (max-width: $tablet) {
      margin-right: 10px;
      font-size: 28px;
    }
  }

  .sortingButton {
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    background: none;
    border: none;

    &:after {
      margin-left: 8px;
      content: '';
      display: block;
      width: 20px;
      height: 9px;
      background: url('../../../../assets/icons/triangle.svg') center no-repeat;
    }

    &.asc:after {
      transform: rotate(180deg);
    }

    @media (max-width: $tablet) {
      display: none;
    }
  }
  .mobileSortingMenu {
    display: none;

    @media (max-width: $tablet) {
      display: block;
    }
  }
}
.subscriptionIndicatorContainer {
  padding-top: 8px;
  height: 63px;
  margin-bottom: 12px;
}
.subscriptionIndicator {
  font-weight: 400;
  color: #e544ff;
  font-size: 11px;
  height: 43px;
  border-radius: 5px;
  box-shadow: 0 0.06rem 0.7rem rgba(72, 78, 85, 0.2);
  margin-left: 64px;
  padding: 0;
  float: left;
  @media (max-width: $tablet) {
    margin-left: 14px;
  }
  &:hover {
    box-shadow: none;
  }
}

.warningEmoji {
  float: left;
  margin-left: 12px;
  margin-top: 11px;
  width: 10px;
  font-size: 14px;
  filter: saturate(0) brightness(1.04);
  cursor: default;
}

.currentPlanText {
  position: relative;
  color: #e544ff;
  float: left;
  margin-left: 14px;
}
.totalToursFraction {
  margin-left: 13px;
  color: #8f9dba;
  font-weight: 400;
  margin-top: 0px;
  font-size: 13px;
  // border: solid 1px;
  height: 23px;
  margin-right: 0px;
  margin-top: 3px;
  width: 51px;
  border-radius: 90px;
  letter-spacing: 1px;
  padding-left: -5px;
}
.overLimitMsg {
  background-color: #929eba;
  position: absolute;
  width: 56px;
  height: 19px;
  font-weight: 500;
  margin-top: -1.5px;
  padding-top: 2.8px;
  margin-left: -21px;
  border-radius: 90px;
  font-size: 8px;
}
.subscriptionLevelText {
  margin-left: 4px;
  font-size: 18px;
  color: #929eba;
  text-transform: uppercase;
}
.trianglePointedRight {
  margin-right: -33px;
  height: 100%;
  /* display: flex; */
  transform: scale(1.5);
  flex-shrink: 0;
  /* margin-right: auto; */
  cursor: pointer;
  background: none;
  border: none;
  transform: rotate(-90deg) scale(0.85);

  &:after {
    margin-left: 11px;
    margin-right: 8px;
    content: '';
    display: block;
    width: 30px;
    height: 9px;
    background: url('../../../../assets/icons/triangle.svg') center no-repeat;
  }

  @media (max-width: $tablet) {
    display: none;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.tourTypePopoverButton {
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  background: none;
  border: none;

  &:after {
    margin-left: 8px;
    content: '';
    display: block;
    width: 20px;
    height: 9px;
    background: url('../../../../assets/icons/triangle.svg') center no-repeat;
  }

  &.opened:after {
    transform: rotate(180deg);
  }

  @media (max-width: $tablet) {
    display: none;
  }
}

.tourTypePopoverContent {
  display: flex;
  flex-direction: column;

  .option {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
}
.toursContainer {
  width: 100%;
  margin: 0 auto 77px;
  padding: 8px 10px 20px;
  box-sizing: border-box;
  background-color: white;

  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(0, 400px));
  grid-gap: 40px;

  @media (max-width: $mobile) {
    grid-template: 1fr / 1fr;
    padding-bottom: 50px;
  }
}
.updateNameModal {
  .nameItem {
    margin-top: 2rem;
    margin-bottom: 4rem;
    input {
      border: thin solid rgba(0, 0, 0, 0.85);
    }
  }
}
.buttonRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  .finishButton {
    margin-left: 1rem;
  }
  .skipItem {
    button.skipButton {
      font-size: 0.75rem;
      padding: 0.5rem 1.2rem;
      height: 2.75rem;
      // padding: 0.25rem;
    }
  }
}
