@import 'src/variables';

.modal {
  max-width: 1200px;
  width: 95% !important;
  border-radius: 16px;

  :global(.ant-modal-content) {
    border-radius: 16px;
  }
}

.container {
  padding: 60px 0;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $bigMobile) {
    padding: 0;
  }

  h2 {
    font-size: 36px;
    color: $black;
    font-weight: 300;
    text-align: center;

    @media (max-width: $bigMobile) {
      font-size: 28px;
    }
  }

  .note {
    margin-top: 10px;
    align-self: flex-end;
    font-size: 12px;
    color: #7B7B7B;
  }

  .link {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    color: $pink;
  }
}

.plansContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.planCard {
  padding: 0 30px;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: $bigMobile) {
    padding: 0 10px;
  }

  .planName {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: $pink;

    @media (max-width: $tablet) {
      font-size: 20px;
    }

    @media (max-width: $bigMobile) {
      margin-right: 6px;
      font-size: 14px;
    }
  }

  > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    height: 100%;

    .angleLeft {
      position: absolute;
      left: -64px;
      width: 130px;
      height: 130px;
      transform: rotate(45deg);
      border-radius: 10px;
      box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
    }

    .price {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      background-color: $white;

      .cost {
        display: flex;
        color: #555555;

        > span {
          display: flex;
          font-size: 70px;
          font-weight: 600;
          line-height: 58px;

          @media (max-width: $tablet) {
            font-size: 40px;
            line-height: 40px;
          }

          &:before {
            content: '$';
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;

            @media (max-width: $tablet) {
              font-size: 16px;
            }
          }
        }

        .cents {
          display: flex;
          flex-direction: column;

          span:first-of-type {
            font-size: 30px;
            font-weight: 600;
            line-height: 28px;

            @media (max-width: $tablet) {
              line-height: 22px;
              font-size: 20px;
            }
          }

          span:last-of-type {
            font-size: 20px;

            @media (max-width: $tablet) {
              font-size: 10px;
            }
          }
        }
      }

      .planNote {
        font-size: 12px;
        color: #7B7B7B;
      }
    }

    .upgradeButton {
      position: relative;
      font-size: 16px;
      height: 40px;

      @media (max-width: $tablet) {
        font-size: 11px;
        padding: 0.6rem;
      }

      @media (max-width: $bigMobile) {
        min-width: 40px;
        padding: 0;
      }

      &:before {
        content: '';
        margin-right: 10px;
        display: block;
        width: 16px;
        height: 20px;
        background: url('../upgrade-icon.svg') no-repeat center;

        @media (max-width: $bigMobile) {
          margin-right: 0;
        }
      }

      .loadingWrapper {
        position: absolute;
        left: calc(50% - 8px);
        top: calc(50% - 8px);
      }
    }
  }
}