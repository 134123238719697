@import 'src/variables';

.formItem {
  .input {
    height: 3.75rem;

    border-radius: 0.25rem;

    @media (max-width: $tablet) {
      height: 2.5rem;
    }

    @media (min-width: $tablet) {
      border-color: $white;
    }

    &:focus {
      box-shadow: none;
    }
  }

  span[class*='ant-input-password'] {
    box-shadow: none;
  }

  div[role='alert'] {
    margin-top: 0.375rem;
    display: flex;
    color: $error;

    &:before {
      content: '';
      margin-right: 0.5rem;
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      background: url('../../../assets/icons/exclamation.svg') no-repeat center;
    }
  }
  :global(.ant-select-selection-search) {
    top: 10px !important;
  }

  :global(.ant-select-selector) {
    height: 60px !important;
    border: 2px solid #f8f8f8 !important;
    border-radius: 4px !important;
    padding: 9px !important;
  }

  .arrow {
    position: relative;
    left: -10px;
    height: 0.5rem;
  }
}

.formItem[class*='ant-form-item-has-error'] {
  :global(.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector),
  .input {
    border: 2px solid $error !important;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: $error !important;
      border-right-width: 2px !important;
      box-shadow: none;
    }
  }
}

:global(.ant-select-item) {
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  border-bottom: 1px solid #f2f2f2;
  &:last-of-type {
    border: none;
  }
}

:global(label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
  display: none !important;
}

:global(label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
  display: inline-block !important;
  margin-right: 4px;
  color: $black;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}