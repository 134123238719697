@import 'src/variables';

.bodyFlow {
  display: flex;
  flex-direction: column;
}

.wrapper {
  max-width: 51rem;
  padding: 0 1.875rem 2.5rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: $bigMobile) {
    padding: 0 0.875rem 2.5rem;
  }
}

.backBtnWrapper {
  max-width: 68.75rem;
  margin: 3.5rem auto 0 auto;
  text-align: left;
  width: 100%;
  padding: 0 1.875rem;
  color: $pink;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
    color: $pink;
  }
  img {
    margin: 0 12px 0 0;
  }
  span {
    font-size: 1rem;
  }
}

.checkboxesWrapper {
  width: 100%;
  margin: 0 0 2.5rem 0;
  > div {
    margin: 0 0 1rem 0;
  }
}

.buttonsContainer {
  margin: 0 0 4rem 0;

  :global(.ant-form-item-control-input-content) {
    width: fit-content;
    display: flex;

    @media (max-width: $tablet) {
      justify-content: center;
    }

    button:first-child {
      margin: 0 1.25rem 0 0;

      @media (max-width: $tablet) {
        margin-right: 0;
      }
    }
  }

  .cancelButton {
    @media (max-width: $tablet) {
      display: none;
    }
  }
}

.textarea {
  width: 100%;
  max-width: 100% !important;
  margin-top: 0 !important;
  resize: none;
}

.header {
  margin: 2.25rem 0;
  font-size: 3.25rem;
  font-weight: 300;
  text-align: center;

  @media (max-width: $tablet) {
    font-size: 1.75rem;
    font-weight: 400;
    margin: 0 0 2.25rem 0;
  }
}

.doubleWrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  > div {
    width: calc((100% - 16px) / 2);
  }

  @media (max-width: $bigMobile) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    > div {
      width: 100%;
    }
  }
}

.selectContainer {
  max-width: 370px;
}

.inputMargin {
  margin-bottom: 2rem !important;
}

.advancedOpt {
  margin: 2rem 0 2rem 0;
  .advancedBtn {
    display: block;
    border: none;
    background: none;
    font-weight: bold;
    font-size: 0.875rem;
    margin: 0 0 1rem 0;
    cursor: pointer;
    img {
      margin: 0 0 0 0.625rem;
      transition: 256ms all;
    }
  }
  .advancedOptBtn {
    background: none;
    font-weight: 600;
    font-size: 14px;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    min-width: 200px;
    max-width: 200px;
    display: block;
    transition: 256ms background;
    &.update {
      border-bottom: 1px solid #dadada;
    }
    &:hover {
      background: #fff;
    }
  }
}

.advancedOptWrapper {
  display: none;
  background: #f8f8f8;
  box-shadow: 0 4px 15px rgba(72, 78, 85, 0.15);
  border-radius: 4px;
  max-width: 200px;
}

.modalWindow {
  max-width: 600px !important;
}
.modalGray {
  :global(.ant-modal-content) {
    background: #f8f8f8;
  }
}

.customErrorWrapper div[role='alert'] {
  margin-top: 0.375rem;
  display: flex;
  color: $error;

  &:before {
    content: '';
    margin-right: 0.5rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    background: url('../../../../assets/icons/exclamation.svg') no-repeat center;
  }
}
.customErrorWrapperAbs {
  margin: 0 0 2rem 0;
  > div:first-child {
    margin: 0 !important;
  }
}
