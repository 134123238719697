//COLORS
$pink: #e544ff;
$lightPink: rgba(229, 68, 255, 0.1); //same as above, but opacity 0.1
$white: #ffffff;
$black: #151a30;
$gray: #a6a6a6;
$lightgray: #dedfe6;
$altgray: #f8f8f8;
$lightblue: #d6dcff;
$error: #4d8dff;

$navGray: #f2f2f2;
$radioButtonBorder: #dadada;
$hoverGray: #404765;

//BREAKPOINTS
$tinyMobile: 21.25rem; // 340px
$smallMobile: 25rem; // 400px
$mobile: 30rem; // 480px
$bigMobile: 37.5rem; // 600px
$smallTablet: 39.375rem; // 630px
$tablet: 48rem; // 768px
$smallScreen: 64rem; // 1024px
$mediumScreen: 75rem; // 1200px
$mediumPlusScreen: 85.375rem; // 1366px

$mediumHeight: 43.75rem;
$mediumPlusHeight: 56.25rem;

// UTILS
$stdShadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
