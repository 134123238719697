@import 'src/variables';

.mobile {
  display: none;
  @media (max-width: $smallScreen) {
    display: block;
  }
}

.desktop {
  display: block;
  @media (max-width: $smallScreen) {
    display: none;
  }
}
