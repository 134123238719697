@import 'src/variables';
@import 'src/mixins';

.blockPlaceholder {
  min-height: 5.375rem;
}

.wrapper {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  &.transparentWrapper {
    background: transparent;
    @include transition(background 0.3s linear);
    &.transparentScrolled {
      background: #fff;
    }
  }
}

.container {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5.375rem;
  max-width: 90rem;
  margin: 0 auto;

  @media (max-width: 57.75rem) {
    justify-content: flex-end;
  }

  .logo {
    position: relative;
    margin-left: 3.75rem;
    z-index: 100;

    @media (max-width: 57.75rem) {
      display: none;
    }
  }

  .navigation {
    position: absolute;
    right: 40px;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      list-style-type: none;

      li {
        margin-right: 2.5rem;

        @media (max-width: $smallScreen) {
          margin-right: 1rem;
        }

        &:last-child {
          margin-right: 0;
        }

        .aboutUsButton,
        a {
          font-size: 0.875rem;
          font-weight: bold;
          color: $hoverGray;

          border: none;
          background-color: inherit;

          @include transition(color 0.2s linear);

          &:hover {
            cursor: pointer;
            color: $pink;
          }

          &.active {
            color: $pink;
          }
        }

        .aboutUsButton span {
          margin-right: 6px;
        }
      }
    }
  }

  .buttonsContainer {
    margin-right: 8.75rem;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 81.25rem) {
      margin-right: 1rem;
    }

    @media (max-width: $smallScreen) {
      margin-right: 4.5rem;
    }

    .signInButton {
      margin-right: 2.1875rem;
      color: $pink;
      font-size: 0.875rem;
      font-weight: bold;

      border: none;
      background: none;

      cursor: pointer;

      @include transition(color 0.3s linear);

      &:hover {
        color: $gray;
      }
    }

    .joinBetaButton {
      font-weight: bold;
    }
  }
}

.popover {
  &:global(.ant-popover) {
    position: fixed;

    z-index: 25;
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }

  :global(.ant-popover-inner) {
    border-radius: 0 0 1rem 1rem;
  }
}

.userPopoverContent,
.aboutUsPopoverContent {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:last-child {
    border-radius: 0 0 1rem 1rem;
  }

  a {
    display: flex;
    align-items: center;
    padding: 0.875rem 2rem;
    overflow: hidden;

    font-weight: bold;

    @include transition(300ms all);

    &:first-child:hover {
      box-shadow: 0 -12px 0 #ffffff, 0 0 12px rgba(0, 0, 0, 0.2);
    }

    &:last-child {
      margin-bottom: 0;

      &:hover {
        box-shadow: 0 12px 6px #ffffff, 0 0 12px rgba(0, 0, 0, 0.2);
      }
    }
    &:hover {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

      :global(.staticIcon) {
        display: none;
      }
      :global(.lottieInner) {
        display: inline-block;
      }
    }

    &.active {
      color: $pink;
    }

    span {
      margin-left: 0.8rem;
    }
  }
}

.aboutUsPopoverContent {
  margin: 15px 0 0;

  a {
    color: $hoverGray;
    font-size: 14px;
    width: 9.125rem;

    &:hover {
      color: $pink;
    }
  }
}

.userPopoverContent {
  margin: 0;

  a {
    color: #a6a6a6;
    font-size: 1rem;
    width: 13.25rem;

    &:hover {
      color: black;
    }
  }

  /*
  .dropdownOption {
  }

  .dashboard:before {
    background: url('../../../assets/icons/dashboard.svg') no-repeat;
  }
  .profile:before {
    background: url('../../../assets/icons/profile.svg') no-repeat;
  }
  .insights:before {
    background: url('../../../assets/icons/insights.svg') no-repeat;
  }
  .logout:before {
    background: url('../../../assets/icons/logout.svg') no-repeat;
  }
  */
}
