@import 'src/variables';
@import 'src/mixins';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  height: 3.25rem;
  font-weight: 600;
  font-size: 0.875rem;

  box-sizing: border-box;
  border-radius: 2rem;

  cursor: pointer;
}

.hollow {
  color: #737683;
  background-color: $white;
  border: 2px solid $pink;

  @include transition(0.3s linear);

  @media (min-width: $tablet) {
    &:hover:enabled {
      color: $white;
      background-color: $pink;
    }
  }
}

.full {
  color: $white;
  background-color: $pink;

  border: 0;

  @include transition(0.3s linear);

  @media (min-width: $tablet) {
    &:hover:enabled {
      color: $white;
      background-color: $hoverGray;
    }
  }
}

.disabled {
  background-color: lightgray;
  cursor: default;
}

.shadow {
  color: $pink;
  background-color: $white;

  // for transition
  border: 2px solid $white;
  box-shadow: 0 0.25rem 1rem rgba(72, 78, 85, 0.15);

  @include transition(border-color 0.3s linear);

  @media (min-width: $tablet) {
    &:hover {
      border: 2px solid $pink;
    }
  }
}

.googleAuthButton,
.appleAuthButton,
.linkedinAuthButton,
.twitterAuthButton,
.emailAuthButton {
  color: $black;

  span {
    display: flex;
    align-items: flex-start;
  }

  span:before {
    margin-right: 0.5rem;
    content: '';
    width: 1.25rem;
    height: 1.25rem;
  }

  @media (min-width: $tablet) {
    &:hover {
      color: $white;
      background-color: $pink;
    }
  }
}

.roundButton {
  padding: 1rem;

  span:before {
    margin-right: 0;
  }
}

.googleAuthButton span:before {
  background: url('../../../assets/icons/google-grey.svg') no-repeat center;
}

.appleAuthButton span:before {
  background: url('../../../assets/icons/apple-grey.svg') no-repeat center;
}

.linkedinAuthButton span:before {
  background: url('../../../assets/icons/linkedin-grey.svg') no-repeat center;
}

.twitterAuthButton span:before {
  background: url('../../../assets/icons/twitter-grey.svg') no-repeat center;
}

.emailAuthButton span:before {
  background: url('../../../assets/icons/email-grey.svg') no-repeat center;
}

.googleAuthButton:hover {
  @media (min-width: $tablet) {
    span:before {
      background: url('../../../assets/icons/google-white.svg') no-repeat center;
    }
  }
}

.twitterAuthButton:hover {
  @media (min-width: $tablet) {
    span:before {
      background: url('../../../assets/icons/twitter-white.svg') no-repeat
        center;
    }
  }
}

.linkedinAuthButton:hover {
  @media (min-width: $tablet) {
    span:before {
      background: url('../../../assets/icons/linkedin-white.svg') no-repeat
        center;
    }
  }
}

.appleAuthButton:hover {
  @media (min-width: $tablet) {
    span:before {
      background: url('../../../assets/icons/apple-white.svg') no-repeat center;
    }
  }
}

.emailAuthButton:hover {
  @media (min-width: $tablet) {
    span:before {
      background: url('../../../assets/icons/email-white.svg') no-repeat center;
    }
  }
}

.animatedSubmitButton {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.75rem;

  :global(.ant-spin-blur::after) {
    opacity: 0;
  }

  :global(.ant-spin) {
    color: $pink;
  }
}
