@import 'src/variables';

.container {
  display: flex;

  :global(.ant-switch) {
    min-width: 40px;
    height: 24px;
    background-color: $white;
  }

  :global(.ant-switch),
  :global(.ant-switch):focus:hover,
  :global(.ant-switch):focus {
    box-shadow: 0 2px 10px rgba(72, 78, 85, 0.35);
  }

  :global(.ant-switch-handle) {
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;

    &:before {
      background-color: #D9D9D9;
    }
  }

  :global(.ant-switch-checked) {
    background-color: $pink;

    :global(.ant-switch-handle) {
      left: calc(100% - 20px);
    }

    :global(.ant-switch-handle):before {
      background-color: $white;
    }
  }
}

.vertical {
  :global(.ant-switch) {
    position: relative;
    top: 8px;
    left: -8px;
    transform: rotate(270deg);
  }
}