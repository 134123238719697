@import 'src/variables';
@import 'src/mixins';

.drawer {
  :global(.ant-drawer-content-wrapper) {
    width: 40% !important;

    @media (max-width: $tablet) {
      width: 100% !important;
    }
  }
}

.drawerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2.5rem;

  button {
    padding: 0;
    background-color: $white;
    border: none;

    width: 1.5rem;
    height: 1.5rem;
  }
}

.popoverContent {
  margin: 0;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .drawerOptionsContainer {
    padding: 0;

    &.userProfileDrawerOptionsContainer {
      :global(.ant-collapse-content-box) {
        margin-left: 3rem;
      }
    }

    :global(.ant-collapse-header) {
      padding: 0 !important;
    }

    :global(.ant-collapse-content-box) {
      margin-left: 2.25rem;
      padding: 0 !important;
    }
  }

  .drawerOption {
    display: flex;
    align-items: center;
    padding: 1rem 0.75rem;
    overflow: hidden;

    color: $black;
    font-size: 1rem;
    font-weight: bold;

    @include transition(0.3s linear);

    &:last-child {
      margin-bottom: 0;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
    }

    span, a {
      margin-left: 1rem;
      color: $hoverGray;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .lastItem {
    border-radius: 0 0 1rem 1rem;
  }
}

.userProfileButton {
  margin-left: 0.25rem;
  padding: 0.75rem 0.25rem;
  flex-direction: row-reverse;

  & > span {
    color: $hoverGray;
  }

  > div {
    width: 2.375rem;
    height: 2.375rem;
    margin-right: 1rem;

    span {
      font-size: 1.25rem;
    }
  }
}

.active {
  &.drawerOption {
    span, a {
      color: $pink;
    }
  }
}