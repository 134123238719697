@import 'src/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.bgWhite {
  background-color: #fff;
}
.bgGray {
  background-color: #f8f8f8;
}

.content {
  // 86 - header, 90 - footer
  min-height: calc(100vh - 86px - 90px);
}

@media (min-width: $tablet) {
  .floatersType1 {
    background: url('../../../assets/images/layout/shape3.png') no-repeat right
      150px;
  }

  .floatersType2 {
    background: url('../../../assets/images/layout/shape3.png') no-repeat right -100px
      top 800px;
  }

  .floatersType3 {
    background: url('../../../assets/images/layout/shape.png') no-repeat right
      400px;
  }

  .floatersType4 {
    background: url('../../../assets/images/layout/shape2.png') no-repeat right
        400px,
      url('../../../assets/images/layout/group.png') no-repeat 100px 750px;
  }

  .floatersType5 {
    background: url('../../../assets/images/layout/shape4.png') no-repeat right
        600px,
      url('../../../assets/images/layout/group.png') no-repeat right 160px top
        400px,
      url('../../../assets/images/layout/group.png') no-repeat 100px 1000px;
  }
}
